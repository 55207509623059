// src/config/index.js
const config = {
    // baseURL: 'https://api.obirealestate.com',
    // baseURL: 'http://34.223.124.205:3000',
    baseURL: 'https://apitest.obirealestate.com',
    imagebaseUrl:"https://obi-stage-bucket.s3.us-west-1.amazonaws.com/obilogo.png"
    
  };
  
  export default config;
  
